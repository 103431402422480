.chart {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.chart.chart--no-results {
    display: none;
}

.chart .tooltip {
    position: fixed;
    background-color: white;
    max-width: 200px;
    padding: 10px;
    /* border-radius: 4px; */
    box-shadow: 0px 0px 10px  rgba(197, 197, 197, 0.5);
    pointer-events: none;
    line-height: 1.5;
}

.border {
    border-radius: 3px !important;
    font-style: normal !important;
}

/*
g {
    pointer-events: bounding-box;
}

.hover {
    opacity: .2;
}

.hover.active {
    opacity: 1;
}
*/