.back,
.details {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.back {
    position: absolute;
    cursor: pointer;
}

.details {
    display: flex;
}

.back.back--hide,
.details.details--hide {
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
}

.details.details--fade-out,
.back.back--fade-out {
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}
